export const ENTE_WEBSITE_LINK = 'https://ente.io';

export const ML_BLOG_LINK = 'https://ente.io/blog/desktop-ml-beta';

export const FACE_SEARCH_PRIVACY_POLICY_LINK =
    'https://ente.io/privacy#8-biometric-information-privacy-policy';

export const SUPPORT_EMAIL = 'support@ente.io';

export const APP_DOWNLOAD_URL = 'https://ente.io/download/desktop';

export const FEEDBACK_EMAIL = 'feedback@ente.io';

export const DELETE_ACCOUNT_EMAIL = 'account-deletion@ente.io';

export const WEB_ROADMAP_URL = 'https://github.com/ente-io/photos-web/issues';

export const DESKTOP_ROADMAP_URL =
    'https://github.com/ente-io/photos-desktop/issues';

export const CITIES_URL = 'https://static.ente.io/world_cities.json';
