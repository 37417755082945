var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0693b1e\n"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { setupSentry } from '@ente/shared/sentry/config/sentry.config.base';

const DEFAULT_SENTRY_DSN =
    'https://bd3656fc40d74d5e8f278132817963a3@sentry.ente.io/2';
setupSentry(DEFAULT_SENTRY_DSN);
